[data-tooltip] {
  position: relative;
  z-index: 11;
}

/* Positioning and visibility settings of the tooltip */

[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */

[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px !important;
  min-width: 50px;
  max-width: 300px;
  width: -webkit-max-content;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  font-style: normal;
  text-align: center;
  white-space: pre-wrap;
  -webkit-transform: translate(-50%, -5px);
          transform: translate(-50%, -5px);
}

/* Tooltip arrow */

[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* Tooltip becomes visible at hover */

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */

[data-tooltip]:hover:before {
  -webkit-transform: translate(-50%, -5px);
          transform: translate(-50%, -5px);
}

/* Slide down effect only on mouseenter (NOT on mouseleave) */

[data-tooltip]:hover:after {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* LEFT */

/* Tooltip + arrow */

[data-tooltip-location=left]:before,
[data-tooltip-location=left]:after {
left: auto;
right: calc(100% + 5px);
bottom: 50%;
}

/* Tooltip */

[data-tooltip-location=left]:before {
-webkit-transform: translate(-5px, 50%);
        transform: translate(-5px, 50%);
}

[data-tooltip-location=left]:hover:before {
-webkit-transform: translate(-5px, 50%);
        transform: translate(-5px, 50%);
}

/* Arrow */

[data-tooltip-location=left]:after {
border-width: 5px 0px 5px 5px;
border-color: transparent transparent transparent rgba(0, 0, 0, 0.9);
-webkit-transform-origin: left;
        transform-origin: left;
-webkit-transform: translateY(50%);
        transform: translateY(50%);
}

[data-tooltip-location=left]:hover:after {
-webkit-transform: translateY(50%);
        transform: translateY(50%);
}

/* RIGHT */

[data-tooltip-location=right]:before,
[data-tooltip-location=right]:after {
left: calc(100% + 5px);
bottom: 50%;
}

[data-tooltip-location=right]:before {
-webkit-transform: translate(5px, 50%);
        transform: translate(5px, 50%);
}

[data-tooltip-location=right]:hover:before {
-webkit-transform: translate(5px, 50%);
        transform: translate(5px, 50%);
}

[data-tooltip-location=right]:after {
border-width: 5px 5px 5px 0px;
border-color: transparent rgba(0, 0, 0, 0.9) transparent transparent;
-webkit-transform: translateY(50%);
        transform: translateY(50%);
}

[data-tooltip-location=right]:hover:after {
-webkit-transform: translateY(50%);
        transform: translateY(50%);
}

/* BOTTOM */

[data-tooltip-location=bottom]:before,
[data-tooltip-location=bottom]:after {
top: calc(100% + 5px);
bottom: auto;
}

[data-tooltip-location=bottom]:before {
-webkit-transform: translate(-50%, 5px);
        transform: translate(-50%, 5px);
}

[data-tooltip-location=bottom]:hover:before {
-webkit-transform: translate(-50%, 5px);
        transform: translate(-50%, 5px);
}

[data-tooltip-location=bottom]:after {
border-width: 0px 5px 5px 5px;
border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
}